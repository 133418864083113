var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: _vm.$t("lbl_tax_invoice_sn") } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { staticClass: "mt-2", attrs: { span: 24 } },
                            [
                              _c("TableCustom", {
                                attrs: {
                                  idtable: "table1",
                                  dataSource:
                                    _vm.dataListTaxInvoiceSerialNumber.data,
                                  columns: _vm.columnsTable,
                                  scroll: { x: "calc(700px + 50%)" },
                                  paginationcustom: true,
                                  defaultPagination: false,
                                  loading: _vm.loadingTable
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "a-space",
                                {
                                  staticStyle: { height: "50px" },
                                  attrs: { align: "center" }
                                },
                                [
                                  _c("Pagination", {
                                    attrs: {
                                      total:
                                        _vm.dataListTaxInvoiceSerialNumber
                                          .totalElements,
                                      pageSizeSet: _vm.limit,
                                      idPagination: "pagination1"
                                    },
                                    on: {
                                      "response-pagesize-change":
                                        _vm.responsePageSizeChange,
                                      "response-currentpage-change":
                                        _vm.responseCurrentPageChange
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              staticClass: "mt-3",
                              attrs: { span: 4, justify: "end" }
                            },
                            [
                              _c(
                                "a-tag",
                                {
                                  staticStyle: { "font-size": "13px" },
                                  attrs: { color: "grey" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("lbl_total_found")) +
                                      " : " +
                                      _vm._s(
                                        _vm.dataListTaxInvoiceSerialNumber
                                          .totalElements
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              staticClass: "mt-2 mb-2",
                              attrs: { span: 24, xs: 24, s: 24, md: 24, lg: 24 }
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "danger" },
                                  on: { click: _vm.cancel }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_cancel")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }