

























































import { Vue, Component } from "vue-property-decorator";
import { settingsServices } from "@/services/settings.service";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import moment from "moment";
import {
  changeCurrencytoNumeric,
  currencyFormat,
} from "@/validator/globalvalidator";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
@Component
export default class DetailTaxInvoiceSerialNumber extends Vue {
  formatCurrencytoNumber = changeCurrencytoNumeric;
  form = this.$form.createForm(this, { name: "detailtaxinvoiceserialnumber" });
  limit = 10 as number;
  page = 0 as number;
  dataListTaxInvoiceSerialNumber = {} as any;
  listDeletedTax = [] as any[];
  labelCol = { span: 6 };
  selectedRowKeys = [] as number[];
  wrapperCol = { span: 16 };
  loadingTable = false as boolean;
  columnsTable = [
    {
      title: this.$t("lbl_dpj_number"),
      dataIndex: "declarationLetterNumber",
      key: "declarationLetterNumber",
      width: 300,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_dpj_date"),
      dataIndex: "declarationLetterDate",
      key: "declarationLetterDate",
      width: 300,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_tax_invoice_sn"),
      dataIndex: "taxInvoiceSn",
      key: "taxInvoiceSn",
      width: 300,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: 300,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customer",
      key: "customer",
      width: 300,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_tax_invoice_number"),
      dataIndex: "taxInvoiceNumber",
      key: "taxInvoiceNumber",
      width: 300,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 300,
      scopedSlots: { customRender: "isNull" },
    },
  ] as ColumnTableCustom[];
  mounted() {
    this.getDetailData();
  }
  getDetailData() {
    let params = {
      limit: this.limit,
      page: this.page,
      search: `invoiceSerialNumberHeader.secureId~${this.$route.params.id}`,
      sorts: "createdDate:asc",
    } as RequestQueryParamsModel;
    this.loadingTable = true;
    settingsServices
      .detailOfTaxInvoiceSerialNumber(params)
      .then(res => {
        res.data.map(
          (item, index) => (
            (item.key = index),
            (item.declarationLetterDate = moment(
              item.declarationLetterDate
            ).format(DEFAULT_DATE_FORMAT)),
            (item.invoiceDate = item.invoiceDate
              ? moment(item.invoiceDate).format(DEFAULT_DATE_FORMAT)
              : null)
          )
        );
        this.dataListTaxInvoiceSerialNumber = res;
      })
      .finally(() => (this.loadingTable = false));
  }
  showConfirmation() {
    if (this.selectedRowKeys.length > 0) {
      this.$confirm({
        title: `Do you want to delete these items?`,
        content: `Total: ${this.selectedRowKeys.length} items will be deleted.`,
        onOk: () => {
          this.handleDeleteRow();
        },
        onCancel() {
          return;
        },
      });
    } else {
      this.$notification.error({
        message: "Error",
        description: "Select at least one row to delete",
      });
    }
  }
  handleSelectTable(value, key, col, _recordOptions): void {
    this.dataListTaxInvoiceSerialNumber[key] = {
      ...this.dataListTaxInvoiceSerialNumber[key],
      [col]: value,
    };
    this.dataListTaxInvoiceSerialNumber =
      this.dataListTaxInvoiceSerialNumber.slice();
  }
  handleInput(
    value,
    key,
    objectColInput,
    _objectColInputName: string,
    onEvt: string
  ) {
    let formatedVal: string | number = value;
    if (onEvt === "onBlur") {
      formatedVal = currencyFormat(value);
    } else if (onEvt === "onFocus") {
      formatedVal = this.formatCurrencytoNumber(value);
    }
    this.dataListTaxInvoiceSerialNumber[key][objectColInput.name] = formatedVal;
    this.dataListTaxInvoiceSerialNumber =
      this.dataListTaxInvoiceSerialNumber.slice();
  }
  onSelectChange(selectedRowKeys) {
    this.selectedRowKeys = selectedRowKeys;
  }
  handleDeleteRow() {
    this.dataListTaxInvoiceSerialNumber =
      this.dataListTaxInvoiceSerialNumber.filter(data => {
        if (this.selectedRowKeys.includes(data.key)) {
          this.listDeletedTax.push(data.id);
        }
        return !this.selectedRowKeys.includes(data.key);
      });
    this.dataListTaxInvoiceSerialNumber.map(
      (data, index) => (data.key = index)
    );
    this.dataListTaxInvoiceSerialNumber =
      this.dataListTaxInvoiceSerialNumber.slice();
    this.selectedRowKeys = [];
  }
  responsePageSizeChange(response: ResponsePagination): void {
    this.limit = response.size;
    this.page = 0;
    this.getDetailData();
  }
  responseCurrentPageChange(response: ResponsePagination): void {
    this.page = response.page - 1;
    this.getDetailData();
  }
  cancel(): void {
    this.$router.push(`/settings/taxinvoiceserialnumber`);
  }
}
